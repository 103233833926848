export const brandsDataHome = [
  {
    img: 'dodo-pizza',
    alt: 'dodo-pizza',
    locale: ['ru', 'en', 'ua', 'kz', 'es'],
  },
  {
    img: 'setters',
    alt: 'setters',
    locale: ['ru', 'en', 'ua', 'kz', 'es'],
  },
  {
    img: 'leroy-merlin',
    alt: 'leroy merlin',
    locale: ['ru', 'en', 'ua', 'kz', 'es'],
  },
  {
    img: 'skillbox',
    alt: 'skillbox',
    locale: ['ru', 'en', 'ua', 'kz', 'es'],
  },
  {
    img: 'sunlight',
    alt: 'sunlight',
    locale: ['ru', 'ua', 'kz'],
  },
  {
    img: 'movavi',
    alt: 'movavi',
    locale: ['en', 'es'],
  },
  {
    img: 'inlov',
    alt: 'inlov',
    locale: ['br'],
  },
  {
    img: 'letras',
    alt: 'letras',
    locale: ['br'],
  },
  {
    img: 'raca',
    alt: 'raca',
    locale: ['br'],
  },
  {
    img: 'terra-parque',
    alt: 'terra-parque',
    locale: ['br'],
  },
  {
    img: 'wework',
    alt: 'wework',
    locale: ['br'],
  },
]

export const brandsDataAccountStats = [
  {
    img: 'dodo-pizza',
    alt: 'dodo-pizza',
    locale: ['ru', 'ua', 'kz'],
  },
  {
    img: 'merlin-left',
    alt: 'leroy merlin',
    locale: ['ru', 'ua', 'kz'],
  },
  {
    img: 'setters-left',
    alt: 'setters',
    locale: ['ru', 'ua', 'kz'],
  },
  {
    img: 'skillbox',
    alt: 'skillbox',
    locale: ['ru', 'ua', 'kz'],
  },
  {
    img: 'pochta-bank',
    alt: 'Почта Банк',
    locale: ['ru', 'ua', 'kz'],
  },
  {
    img: 'inlov',
    alt: 'inlov',
    locale: ['br'],
  },
  {
    img: 'letras',
    alt: 'letras',
    locale: ['br'],
  },
  {
    img: 'raca',
    alt: 'raca',
    locale: ['br'],
  },
  {
    img: 'terra-parque',
    alt: 'terra-parque',
    locale: ['br'],
  },
  {
    img: 'wework',
    alt: 'wework',
    locale: ['br'],
  },
  {
    img: 'dodo-pizza',
    alt: 'dodo-pizza',
    locale: ['en', 'es'],
  },
  {
    img: 'setters',
    alt: 'setters',
    locale: ['en', 'es'],
  },
  {
    img: 'leroy-merlin',
    alt: 'leroy merlin',
    locale: ['en', 'es'],
  },
  {
    img: 'skillbox',
    alt: 'skillbox',
    locale: ['en', 'es'],
  },
  {
    img: 'movavi',
    alt: 'movavi',
    locale: ['en', 'es'],
  },
]

export const brandsDataMonitoring = [
  {
    img: 'pochta-bank',
    alt: 'Почта Банк',
    locale: ['ru', 'ua', 'kz'],
  },
  {
    img: 'paolo-conte',
    alt: 'paolo-conte',
    locale: ['ru', 'ua', 'kz'],
  },
  {
    img: 'redmond',
    alt: 'redmond',
    locale: ['ru', 'ua', 'kz'],
  },
  {
    img: 'solemate',
    alt: 'solemate',
    locale: ['ru', 'ua', 'kz'],
  },
  {
    img: 'idea-nova',
    alt: 'idea-nova',
    locale: ['ru', 'ua', 'kz'],
  },
  {
    img: 'inlov',
    alt: 'inlov',
    locale: ['br'],
  },
  {
    img: 'letras',
    alt: 'letras',
    locale: ['br'],
  },
  {
    img: 'raca',
    alt: 'raca',
    locale: ['br'],
  },
  {
    img: 'terra-parque',
    alt: 'terra-parque',
    locale: ['br'],
  },
  {
    img: 'wework',
    alt: 'wework',
    locale: ['br'],
  },
  {
    img: 'dodo-pizza',
    alt: 'dodo-pizza',
    locale: ['en', 'es'],
  },
  {
    img: 'setters',
    alt: 'setters',
    locale: ['en', 'es'],
  },
  {
    img: 'leroy-merlin',
    alt: 'leroy merlin',
    locale: ['en', 'es'],
  },
  {
    img: 'skillbox',
    alt: 'skillbox',
    locale: ['en', 'es'],
  },
  {
    img: 'movavi',
    alt: 'movavi',
    locale: ['en', 'es'],
  },
]

export const brandsDataAutoposting = [
  {
    img: 'gf',
    alt: 'grow food',
    locale: ['ru', 'ua', 'kz'],
  },
  {
    img: 'movavi',
    alt: 'movavi',
    locale: ['ru', 'ua', 'kz'],
  },
  {
    img: 'gloria-jeans',
    alt: 'gloria-jeans',
    locale: ['ru', 'ua', 'kz'],
  },
  {
    img: 'terralife',
    alt: 'terralife',
    locale: ['ru', 'ua', 'kz'],
  },
  {
    img: 'imperatorskiy',
    alt: 'imperatorskiy',
    locale: ['ru', 'ua', 'kz'],
  },
  {
    img: 'inlov',
    alt: 'inlov',
    locale: ['br'],
  },
  {
    img: 'letras',
    alt: 'letras',
    locale: ['br'],
  },
  {
    img: 'raca',
    alt: 'raca',
    locale: ['br'],
  },
  {
    img: 'terra-parque',
    alt: 'terra-parque',
    locale: ['br'],
  },
  {
    img: 'wework',
    alt: 'wework',
    locale: ['br'],
  },
  {
    img: 'dodo-pizza',
    alt: 'dodo-pizza',
    locale: ['en', 'es'],
  },
  {
    img: 'setters',
    alt: 'setters',
    locale: ['en', 'es'],
  },
  {
    img: 'leroy-merlin',
    alt: 'leroy merlin',
    locale: ['en', 'es'],
  },
  {
    img: 'skillbox',
    alt: 'skillbox',
    locale: ['en', 'es'],
  },
  {
    img: 'movavi',
    alt: 'movavi',
    locale: ['en', 'es'],
  },
]

export const brandsDataReports = [
  {
    img: 'pochta-bank',
    alt: 'Почта Банк',
    locale: ['ru', 'ua', 'kz'],
  },
  {
    img: 'marvelous',
    alt: 'marvelous',
    locale: ['ru', 'ua', 'kz'],
  },
  {
    img: 'mann-ivanov-ferber',
    alt: 'mann-ivanov-ferber',
    locale: ['ru', 'ua', 'kz'],
  },
  {
    img: 'roza-hutor',
    alt: 'роза хутор',
    locale: ['ru', 'ua', 'kz'],
  },
  {
    img: 'rostelekom',
    alt: 'ростелеком',
    locale: ['ru', 'ua', 'kz'],
  },
  {
    img: 'inlov',
    alt: 'inlov',
    locale: ['br'],
  },
  {
    img: 'letras',
    alt: 'letras',
    locale: ['br'],
  },
  {
    img: 'raca',
    alt: 'raca',
    locale: ['br'],
  },
  {
    img: 'terra-parque',
    alt: 'terra-parque',
    locale: ['br'],
  },
  {
    img: 'wework',
    alt: 'wework',
    locale: ['br'],
  },
  {
    img: 'dodo-pizza',
    alt: 'dodo-pizza',
    locale: ['en', 'es'],
  },
  {
    img: 'setters',
    alt: 'setters',
    locale: ['en', 'es'],
  },
  {
    img: 'leroy-merlin',
    alt: 'leroy merlin',
    locale: ['en', 'es'],
  },
  {
    img: 'skillbox',
    alt: 'skillbox',
    locale: ['en', 'es'],
  },
  {
    img: 'movavi',
    alt: 'movavi',
    locale: ['en', 'es'],
  },
]

export const brandsDataBloggersCheck = [
  {
    img: 'worldclass',
    alt: 'worldclass',
    locale: ['ru', 'ua', 'kz'],
  },
  {
    img: 'bel-bimbo',
    alt: 'bel-bimbo',
    locale: ['ru', 'ua', 'kz'],
  },
  {
    img: 'merlin',
    alt: 'leroy merlin',
    locale: ['ru', 'ua', 'kz'],
  },
  {
    img: 'rumikom',
    alt: 'rumikom',
    locale: ['ru', 'ua', 'kz'],
  },
  {
    img: 'one-touch',
    alt: 'one-touch',
    locale: ['ru', 'ua', 'kz'],
  },
  {
    img: 'inlov',
    alt: 'inlov',
    locale: ['br'],
  },
  {
    img: 'letras',
    alt: 'letras',
    locale: ['br'],
  },
  {
    img: 'raca',
    alt: 'raca',
    locale: ['br'],
  },
  {
    img: 'terra-parque',
    alt: 'terra-parque',
    locale: ['br'],
  },
  {
    img: 'wework',
    alt: 'wework',
    locale: ['br'],
  },
  {
    img: 'dodo-pizza',
    alt: 'dodo-pizza',
    locale: ['en', 'es'],
  },
  {
    img: 'setters',
    alt: 'setters',
    locale: ['en', 'es'],
  },
  {
    img: 'leroy-merlin',
    alt: 'leroy merlin',
    locale: ['en', 'es'],
  },
  {
    img: 'skillbox',
    alt: 'skillbox',
    locale: ['en', 'es'],
  },
  {
    img: 'movavi',
    alt: 'movavi',
    locale: ['en', 'es'],
  },
]

export const brandsDataKpi = [
  {
    img: 'smmashing-media',
    alt: 'smmashing-media',
    locale: ['ru', 'ua', 'kz'],
  },
  {
    img: 'kontur',
    alt: 'контур',
    locale: ['ru', 'ua', 'kz'],
  },
  {
    img: 'red-graphic',
    alt: 'red-graphic',
    locale: ['ru', 'ua', 'kz'],
  },
  {
    img: 'beauty-sleep',
    alt: 'beauty-sleep',
    locale: ['ru', 'ua', 'kz'],
  },
  {
    img: 'pochta-bank',
    alt: 'Почта Банк',
    locale: ['ru', 'ua', 'kz'],
  },
  {
    img: 'inlov',
    alt: 'inlov',
    locale: ['br'],
  },
  {
    img: 'letras',
    alt: 'letras',
    locale: ['br'],
  },
  {
    img: 'raca',
    alt: 'raca',
    locale: ['br'],
  },
  {
    img: 'terra-parque',
    alt: 'terra-parque',
    locale: ['br'],
  },
  {
    img: 'wework',
    alt: 'wework',
    locale: ['br'],
  },
  {
    img: 'dodo-pizza',
    alt: 'dodo-pizza',
    locale: ['en', 'es'],
  },
  {
    img: 'setters',
    alt: 'setters',
    locale: ['en', 'es'],
  },
  {
    img: 'leroy-merlin',
    alt: 'leroy merlin',
    locale: ['en', 'es'],
  },
  {
    img: 'skillbox',
    alt: 'skillbox',
    locale: ['en', 'es'],
  },
  {
    img: 'movavi',
    alt: 'movavi',
    locale: ['en', 'es'],
  },
]

export const brandsDataPricing = [
  {
    img: 'world-class',
    alt: 'worldclass',
    locale: ['ru', 'en', 'ua', 'kz', 'es'],
  },
  {
    img: 'leroy-merlin',
    alt: 'leroy merlin',
    locale: ['ru', 'en', 'ua', 'kz', 'es'],
  },
  {
    img: 'setters',
    alt: 'Setters',
    locale: ['ru', 'en', 'ua', 'kz', 'es'],
  },
  {
    img: 'skillbox',
    alt: 'skillbox',
    locale: ['ru', 'en', 'ua', 'kz', 'es'],
  },
  {
    img: 'sunlight',
    alt: 'sunlight',
    locale: ['ru', 'en', 'ua', 'kz', 'es'],
  },
  {
    img: 'inlov',
    alt: 'inlov',
    locale: ['br'],
  },
  {
    img: 'letras',
    alt: 'letras',
    locale: ['br'],
  },
  {
    img: 'raca',
    alt: 'raca',
    locale: ['br'],
  },
  {
    img: 'terra-parque',
    alt: 'terra-parque',
    locale: ['br'],
  },
  {
    img: 'wework',
    alt: 'wework',
    locale: ['br'],
  },
]

export const brandsDataDirectInstagram = [
  {
    img: 'ingate',
    alt: 'Ingate',
    locale: ['ru', 'ua', 'kz'],
  },
  {
    img: 'crop-media',
    alt: 'Crop Media',
    locale: ['ru', 'ua', 'kz'],
  },
  {
    img: 'tez-tour',
    alt: 'TEZ Tour',
    locale: ['ru', 'ua', 'kz'],
  },
  {
    img: 'bel-bimbo-right',
    alt: 'bel-bimbo',
    locale: ['ru', 'ua', 'kz'],
  },
  {
    img: 'imodern',
    alt: 'iModern',
    locale: ['ru', 'ua', 'kz'],
  },
  {
    img: 'inlov',
    alt: 'inlov',
    locale: ['br'],
  },
  {
    img: 'letras',
    alt: 'letras',
    locale: ['br'],
  },
  {
    img: 'raca',
    alt: 'raca',
    locale: ['br'],
  },
  {
    img: 'terra-parque',
    alt: 'terra-parque',
    locale: ['br'],
  },
  {
    img: 'wework',
    alt: 'wework',
    locale: ['br'],
  },
  {
    img: 'dodo-pizza',
    alt: 'dodo-pizza',
    locale: ['en', 'es'],
  },
  {
    img: 'setters',
    alt: 'setters',
    locale: ['en', 'es'],
  },
  {
    img: 'leroy-merlin',
    alt: 'leroy merlin',
    locale: ['en', 'es'],
  },
  {
    img: 'skillbox',
    alt: 'skillbox',
    locale: ['en', 'es'],
  },
  {
    img: 'movavi',
    alt: 'movavi',
    locale: ['en', 'es'],
  },
]

export const brandsDataInstagramStoriesStats = [
  {
    img: 'setters-left',
    alt: 'Setters',
    locale: ['ru', 'ua', 'kz'],
  },

  {
    img: 'mann-ivanov-ferber',
    alt: 'mann-ivanov-ferber',
    locale: ['ru', 'ua', 'kz'],
  },
  {
    img: 'skillbox-right',
    alt: 'skillbox',
    locale: ['ru', 'ua', 'kz'],
  },
  {
    img: 'worldclass-right',
    alt: 'worldclass',
    locale: ['ru', 'ua', 'kz'],
  },
  {
    img: 'inlov',
    alt: 'inlov',
    locale: ['br'],
  },
  {
    img: 'letras',
    alt: 'letras',
    locale: ['br'],
  },
  {
    img: 'raca',
    alt: 'raca',
    locale: ['br'],
  },
  {
    img: 'terra-parque',
    alt: 'terra-parque',
    locale: ['br'],
  },
  {
    img: 'wework',
    alt: 'wework',
    locale: ['br'],
  },
  {
    img: 'dodo-pizza',
    alt: 'dodo-pizza',
    locale: ['en', 'es'],
  },
  {
    img: 'setters',
    alt: 'setters',
    locale: ['en', 'es'],
  },
  {
    img: 'leroy-merlin',
    alt: 'leroy merlin',
    locale: ['en', 'es'],
  },
  {
    img: 'skillbox',
    alt: 'skillbox',
    locale: ['en', 'es'],
  },
  {
    img: 'movavi',
    alt: 'movavi',
    locale: ['en', 'es'],
  },
]

export const brandsDataAbout = [
  {
    img: 'rostelekom',
    alt: 'Rostelekom',
    locale: ['ru', 'ua', 'kz'],
    aboutPage: true,
  },
  {
    img: 'vk-logo',
    alt: 'VK',
    locale: ['ru', 'ua', 'kz'],
    aboutPage: true,
  },
  {
    img: 'worldclass',
    alt: 'WorldClass',
    locale: ['ru', 'ua', 'kz'],
    aboutPage: true,
  },
  {
    img: 'setters',
    alt: 'SETTERS',
    locale: ['ru', 'ua', 'kz'],
    aboutPage: true,
  },
  {
    img: 'merlin',
    alt: 'Leroy Merlin',
    locale: ['ru', 'ua', 'kz'],
    aboutPage: true,
  },
  {
    img: 'dodo',
    alt: 'Dodo Pizza',
    locale: ['ru', 'ua', 'kz'],
    aboutPage: true,
  },
  {
    img: 'gold-apple',
    alt: 'Gold Apple',
    locale: ['ru', 'ua', 'kz'],
    aboutPage: true,
  },
  {
    img: 'roza-hutor',
    alt: 'Roza Hutor',
    locale: ['ru', 'ua', 'kz'],
    aboutPage: true,
  },
  {
    img: 'redmond',
    alt: 'Redmond',
    locale: ['ru', 'ua', 'kz'],
    aboutPage: true,
  },
  {
    img: 'skillbox',
    alt: 'Skillbox',
    locale: ['ru', 'ua', 'kz'],
    aboutPage: true,
  },
  {
    img: 'red-graphic',
    alt: 'Red Graphic',
    locale: ['ru', 'ua', 'kz'],
    aboutPage: true,
  },
  {
    img: 'sunlight',
    alt: 'Sunlight',
    locale: ['ru', 'ua', 'kz'],
    aboutPage: true,
  },
  {
    img: 'grow-food',
    alt: 'Grow Food',
    locale: ['ru', 'ua', 'kz'],
    aboutPage: true,
  },
  {
    img: 'smmashing',
    alt: 'Smmashing Media',
    locale: ['ru', 'ua', 'kz'],
    aboutPage: true,
  },
  {
    img: 'fk-dinamo',
    alt: 'FC DINAMO',
    locale: ['ru', 'ua', 'kz'],
    aboutPage: true,
  },
  {
    img: 'movavi',
    alt: 'MOVAVI',
    locale: ['ru', 'ua', 'kz'],
    aboutPage: true,
  },
  {
    img: 'sleep',
    alt: 'Beauty Sleep',
    locale: ['ru', 'ua', 'kz'],
    aboutPage: true,
  },
  {
    img: 'gj',
    alt: 'Gloria Jeans',
    locale: ['ru', 'ua', 'kz'],
    aboutPage: true,
  },
  {
    img: 'rambler',
    alt: 'RAMBLER&Co',
    locale: ['ru', 'ua', 'kz'],
    aboutPage: true,
  },
  {
    img: 'idea',
    alt: 'Idea Nova',
    locale: ['ru', 'ua', 'kz'],
    aboutPage: true,
  },
  {
    img: 'inlov',
    alt: 'inlov',
    locale: ['br'],
    aboutPage: false,
  },
  {
    img: 'letras',
    alt: 'letras',
    locale: ['br'],
    aboutPage: false,
  },
  {
    img: 'raca',
    alt: 'raca',
    locale: ['br'],
    aboutPage: false,
  },
  {
    img: 'terra-parque',
    alt: 'terra-parque',
    locale: ['br'],
    aboutPage: false,
  },
  {
    img: 'wework',
    alt: 'wework',
    locale: ['br'],
    aboutPage: false,
  },
  {
    img: 'dodo-pizza',
    alt: 'dodo-pizza',
    locale: ['en', 'es'],
    aboutPage: false,
  },
  {
    img: 'setters',
    alt: 'setters',
    locale: ['en', 'es'],
    aboutPage: false,
  },
  {
    img: 'leroy-merlin',
    alt: 'leroy merlin',
    locale: ['en', 'es'],
    aboutPage: false,
  },
  {
    img: 'skillbox',
    alt: 'skillbox',
    locale: ['en', 'es'],
    aboutPage: false,
  },
  {
    img: 'movavi',
    alt: 'movavi',
    locale: ['en', 'es'],
    aboutPage: false,
  },
]
export const brandsDataContacts = [
  {
    img: 'rostelekom',
    alt: 'Rostelekom',
    locale: ['ru', 'ua', 'kz'],
    aboutPage: true,
  },
  {
    img: 'vk-logo',
    alt: 'VK',
    locale: ['ru', 'ua', 'kz'],
    aboutPage: true,
  },
  {
    img: 'worldclass',
    alt: 'WorldClass',
    locale: ['ru', 'ua', 'kz'],
    aboutPage: true,
  },
  {
    img: 'setters',
    alt: 'SETTERS',
    locale: ['ru', 'ua', 'kz'],
    aboutPage: true,
  },
  {
    img: 'merlin',
    alt: 'Leroy Merlin',
    locale: ['ru', 'ua', 'kz'],
    aboutPage: true,
  },
  {
    img: 'dodo',
    alt: 'Dodo Pizza',
    locale: ['ru', 'ua', 'kz'],
    aboutPage: true,
  },
  {
    img: 'gold-apple',
    alt: 'Gold Apple',
    locale: ['ru', 'ua', 'kz'],
    aboutPage: true,
  },
  {
    img: 'roza-hutor',
    alt: 'Roza Hutor',
    locale: ['ru', 'ua', 'kz'],
    aboutPage: true,
  },
  {
    img: 'redmond',
    alt: 'Redmond',
    locale: ['ru', 'ua', 'kz'],
    aboutPage: true,
  },
  {
    img: 'skillbox',
    alt: 'Skillbox',
    locale: ['ru', 'ua', 'kz'],
    aboutPage: true,
  },
  {
    img: 'red-graphic',
    alt: 'Red Graphic',
    locale: ['ru', 'ua', 'kz'],
    aboutPage: true,
  },
  {
    img: 'sunlight',
    alt: 'Sunlight',
    locale: ['ru', 'ua', 'kz'],
    aboutPage: true,
  },
  {
    img: 'grow-food',
    alt: 'Grow Food',
    locale: ['ru', 'ua', 'kz'],
    aboutPage: true,
  },
  {
    img: 'smmashing',
    alt: 'Smmashing Media',
    locale: ['ru', 'ua', 'kz'],
    aboutPage: true,
  },
  {
    img: 'fk-dinamo',
    alt: 'FC DINAMO',
    locale: ['ru', 'ua', 'kz'],
    aboutPage: true,
  },
  {
    img: 'movavi',
    alt: 'MOVAVI',
    locale: ['ru', 'ua', 'kz'],
    aboutPage: true,
  },
  {
    img: 'sleep',
    alt: 'Beauty Sleep',
    locale: ['ru', 'ua', 'kz'],
    aboutPage: true,
  },
  {
    img: 'gj',
    alt: 'Gloria Jeans',
    locale: ['ru', 'ua', 'kz'],
    aboutPage: true,
  },
  {
    img: 'rambler',
    alt: 'RAMBLER&Co',
    locale: ['ru', 'ua', 'kz'],
    aboutPage: true,
  },
  {
    img: 'idea',
    alt: 'Idea Nova',
    locale: ['ru', 'ua', 'kz'],
    aboutPage: true,
  },
  {
    img: 'inlov',
    alt: 'inlov',
    locale: ['br'],
    aboutPage: false,
  },
  {
    img: 'letras',
    alt: 'letras',
    locale: ['br'],
    aboutPage: false,
  },
  {
    img: 'raca',
    alt: 'raca',
    locale: ['br'],
    aboutPage: false,
  },
  {
    img: 'terra-parque',
    alt: 'terra-parque',
    locale: ['br'],
    aboutPage: false,
  },
  {
    img: 'wework',
    alt: 'wework',
    locale: ['br'],
    aboutPage: false,
  },
  {
    img: 'dodo-pizza',
    alt: 'dodo-pizza',
    locale: ['en'],
    aboutPage: false,
  },
  {
    img: 'setters',
    alt: 'setters',
    locale: ['en'],
    aboutPage: false,
  },
  {
    img: 'leroy-merlin',
    alt: 'leroy merlin',
    locale: ['en'],
    aboutPage: false,
  },
  {
    img: 'skillbox',
    alt: 'skillbox',
    locale: ['en'],
    aboutPage: false,
  },
  {
    img: 'movavi',
    alt: 'movavi',
    locale: ['en'],
    aboutPage: false,
  },
]

export const brandsDataServices = [
  {
    img: 'setters',
    alt: 'setters',
    locale: ['ru', 'ua', 'kz'],
  },
  {
    img: 'merlin',
    alt: 'leroy merlin',
    locale: ['ru', 'ua', 'kz'],
  },
  {
    img: 'skillbox',
    alt: 'skillbox',
    locale: ['ru', 'ua', 'kz'],
  },
  {
    img: 'sunlight',
    alt: 'sunlight',
    locale: ['ru', 'ua', 'kz'],
  },
  {
    img: 'inlov',
    alt: 'inlov',
    locale: ['br'],
  },
  {
    img: 'letras',
    alt: 'letras',
    locale: ['br'],
  },
  {
    img: 'raca',
    alt: 'raca',
    locale: ['br'],
  },
  {
    img: 'terra-parque',
    alt: 'terra-parque',
    locale: ['br'],
  },
  {
    img: 'wework',
    alt: 'wework',
    locale: ['br'],
  },
  {
    img: 'dodo-pizza',
    alt: 'dodo-pizza',
    locale: ['en', 'es'],
  },
  {
    img: 'setters',
    alt: 'setters',
    locale: ['en', 'es'],
  },
  {
    img: 'leroy-merlin',
    alt: 'leroy merlin',
    locale: ['en', 'es'],
  },
  {
    img: 'skillbox',
    alt: 'skillbox',
    locale: ['en', 'es'],
  },
  {
    img: 'movavi',
    alt: 'movavi',
    locale: ['en', 'es'],
  },
]
